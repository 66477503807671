import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import Image from './Image';
import copy from '../../img/icons/makeACopy.svg';
import Translations from '../helpers/Translations';
import Url from '../helpers/Url';

const Header4MenuCopyPositionButton = ({ displaySize, isLoading }) => {
    const navigate = useNavigate();
    const { isMobile } = displaySize;

    if (isLoading) {
        return (
            <div className="extended-action inverted">
                <SkeletonRectangular width={97} height={18} borderRadius={26} />
            </div>
        );
    }

    return (
        <div onClick={() => navigate(Url.build('create-job-ad'))} className="extended-action">
            <div className="extended-action__icon-status">
                <Image size="tiny" src={copy} />
            </div>
            {!isMobile && (
                <div className="position-detail-button">
                    {Translations.getStatic('makeACopy')}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
    isDuplicateJob: state.createJobModel.isDuplicateJob,
});

export default connect(mapStateToProps, null)(Header4MenuCopyPositionButton);

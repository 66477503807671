import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import { useTranslationInPageLanguage, useGetLocale } from '@profesia/mark-offer-form/i18n';
import Modal from '@profesia/adamui/components/common/modal/Modal';
import ModalWarningNotificationBox from '@profesia/mark-offer-form/components/content/form/components/ModalWarningNotificationBox';
import { FORM_COMPANY_ID } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { ValidationSchema } from '@profesia/mark-offer-form/components/content/form/types';
import useGetResolver from '@profesia/mark-offer-form/components/content/form/resolver';
import getSetValue from '@profesia/mark-offer-form/components/content/form/getSetValue';
import { useCompanyDetailsUpdate } from '@profesia/mark-offer-form/services/companyDetails/useCompanyDetailsUpdate/index';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
import Form from '../Form';
import EditLogoModal from '../EditLogoModal';
const CompanyModal = ({ company, isOpen, onClose, onSubmit }) => {
    const currentLanguage = useGetLocale();
    const pageT = useTranslationInPageLanguage('companyDetails.companyModal');
    const commonT = useTranslationInPageLanguage('common');
    const buttonsT = useTranslationInPageLanguage('buttons');
    const { getValues } = useFormContext();
    const companyId = getValues(FORM_COMPANY_ID);
    const resolver = useGetResolver(ValidationSchema.Company);
    const addToast = useToastWithTranslation();
    const onError = () => addToast({ message: 'common.responseError.saveDataFailed', variant: 'error' });
    const onSuccess = (data) => {
        onSubmit(data);
        addToast({ message: 'common.responseSuccess.saveDataSuccess', variant: 'success' });
    };
    const { mutate: updateCompanyDetails, isPending } = useCompanyDetailsUpdate(onSuccess, onError);
    const formData = useForm({
        defaultValues: company,
        resolver: resolver,
    });
    const { setValue: setFormValue, formState, clearErrors, handleSubmit } = formData;
    const { errors } = formState;
    const setFormProviderValue = getSetValue(setFormValue, clearErrors, errors);
    const onFormSubmit = (data) => {
        updateCompanyDetails({
            companyId,
            characteristics: data.company.characteristics.trim(),
            businessAreaId: data.company.businessArea.id,
            languageFlag: currentLanguage,
        });
    };
    const { form } = getConfiguration();
    const { isEditMode } = useOptionsContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalSecondOpen, setIsModalSecondOpen] = useState(false);
    const [isModalSecondClosing, setIsModalSecondClosing] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsModalSecondOpen(true);
        setIsModalSecondClosing(false);
        if (isEditMode) {
            form.openModalThird();
        }
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsModalSecondOpen(false);
        setIsModalSecondClosing(true);
        if (isEditMode) {
            form.closeModalThird();
        }
    };
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, spacing: 4, isModalSecond: isModalSecondOpen, isModalSecondClosing: isModalSecondClosing, title: pageT('title'), closeButtonText: commonT('actions.cancel'), shouldUseOffsetTop: false, footer: _jsx(FormFooter, { isLoading: isPending, submitAction: handleSubmit(onFormSubmit), submitButtonText: buttonsT('save'), onClose: onClose, closeButtonText: buttonsT('cancel') }), content: _jsxs("div", { children: [_jsx(ModalWarningNotificationBox, { children: pageT('info.description') }), _jsxs(FormProvider, { ...formData, setValue: setFormProviderValue, children: [_jsx(Form, { handleOpenLogoModal: handleOpenModal }), _jsx(EditLogoModal, { onClose: handleCloseModal, isOpen: isModalOpen })] })] }), isSaving: isPending, isSavingText: commonT('saving'), testId: "company-modal" }));
};
export default CompanyModal;

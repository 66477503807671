import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import HiddenFields from '@profesia/adamui/components/common/HiddenFields';
import { NumberField } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { LOCATION_JOB_AD, NAME_YEARS_OF_EXPERIENCE, } from '@profesia/mark-offer-form/utils/track/constants';
import { FORM_SKILLS_YEARS_OF_EXPERIENCE } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { NARROW_INPUT_WIDTH } from '@profesia/mark-offer-form/components/content/form/constants';
const YearsOfExperience = () => {
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_SKILLS_YEARS_OF_EXPERIENCE, control });
    const t = useGetFixedT('skills.otherSkills.practiceAtPosition');
    return (_jsx(HiddenFields, { buttonLabel: t('years'), shouldBeHidden: !value, children: _jsx(NumberField, { label: t('years'), value: value ?? undefined, onChange: (newValue) => {
                setValue(FORM_SKILLS_YEARS_OF_EXPERIENCE, newValue);
            }, inputWidth: NARROW_INPUT_WIDTH, disableZeroValue: true, "data-testid": "practice-years-input" }), onClick: () => trackEvent(LOCATION_JOB_AD, NAME_YEARS_OF_EXPERIENCE, null) }));
};
export default YearsOfExperience;

import { useEffect } from 'react';
import scrollToFirstError from '../scrollToFirstError';
const useScrollToFirstError = (errors) => {
    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            scrollToFirstError();
        }
    }, [errors]);
};
export default useScrollToFirstError;

import { RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES, RECIEVE_JOB_ORDER_TIERS, REQUEST_JOB_ORDER_ADDITIONAL_SERVICES, REQUEST_JOB_ORDER_TIERS, SET_JOB_ORDER_SELECTED_TIER } from "../actions/JobOrderActions";

export const orderModel = (state = {}, action) => {
    switch (action.type) {
        case RECIEVE_JOB_ORDER_TIERS:
            return {
                ...state,
                tiers: action.payload,
            };
        case RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES:
            return {
                ...state,
                additionalServices: action.payload,
            }
        case SET_JOB_ORDER_SELECTED_TIER:
            return {
                ...state,
                selectedTier: action.payload,
            }
        default:
            return state;
    }
};

export const isFetchingJobOrderTiers= (state = false, action) => {
    switch (action.type) {
        case REQUEST_JOB_ORDER_TIERS:
            return true;

        case RECIEVE_JOB_ORDER_TIERS:
            return false;

        default:
            return state;
    }
};

export const isFetchingJobOrderAdditionalServices= (state = false, action) => {
    switch (action.type) {
        case REQUEST_JOB_ORDER_ADDITIONAL_SERVICES:
            return true;

        case RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES:
            return false;

        default:
            return state;
    }
};
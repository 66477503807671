import React, {useState} from 'react';
import Url from './../../helpers/Url';
import Icon from '../Icon';
import {openModal} from '../../actions/ModalActions';
import {connect} from 'react-redux';
import {openNotAllowedFeatureDialog} from '../../actions/DialogActions';
import Constants from '../../helpers/Constants';
import Translations from '../../helpers/Translations';
import Link from '../../helpers/overridden/Link';
import Popup from '../Popup';

const MenuItem = props => {
    const [submenuShown, setSubmenuShown]       = useState(props.submenuShown || false);
    const [subsubmenuShown, setSubsubmenuShown] = useState('');

    const {item, action, menuItemsStrings} = props;

    if (item.visible === false) {
        return null;
    }

    const isActive = item.hasOwnProperty('activeActions') === true && item.activeActions.indexOf(action) !== -1;
    const isLocked = item.hasOwnProperty('locked') === true && item.locked === true;

    const isLockedClassName = isLocked === true
        ? Constants.FEATURE_PERMISSION_CLASS_NAME
        : '';

    const icon = item.hasOwnProperty('icon') === true
        ? <Icon name={item.icon}/>
        : null;

    const allowedInPackage = item.hasOwnProperty('allowedInPackage') === true
        ? item.allowedInPackage
        : null;

    let menuLink;

    const title = item.title && item.title !== 'admin' 
        ? Translations.getStatic(item.title) 
        : menuItemsStrings[item.name || item.action];

    const submenu = item.hasOwnProperty('submenu') === true
        && item.submenu.length > 0
        && submenuShown === true
        && (
            <ul className="dropdown-menu">
                {
                    item.submenu.filter(submenuItem => submenuItem.hasOwnProperty('visible') === false || submenuItem.visible === true)
                        .map(submenuItem => {
                            let submenuItemTitle = '';

                            if (submenuItem.hasOwnProperty('title') === true) {
                                submenuItemTitle = submenuItem.title === 'admin' ? 'Admin' : Translations.getStatic(submenuItem.title);
                            } else {
                                submenuItemTitle = Translations.getStatic(submenuItem.action);
                            }

                            const isExternal      = submenuItem.hasOwnProperty('url') === true;
                            const hasSubsubmenu   = submenuItem.hasOwnProperty('submenu') === true;
                            const subsubmenuItems = hasSubsubmenu === true ? submenuItem.submenu.filter(subsubmenuItem => subsubmenuItem.hasOwnProperty('visible') === false || subsubmenuItem.visible === true) : [];

                            return (
                                <li key={submenuItemTitle} onMouseEnter={() => setSubsubmenuShown(submenuItemTitle)} onMouseLeave={() => setSubsubmenuShown('')}>
                                    {
                                        isExternal === true
                                            ? (
                                                <a
                                                    href={submenuItem.url}
                                                    title={submenuItem.title}
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    {submenuItemTitle}
                                                </a>
                                            )
                                            : (
                                                <Link
                                                    to={Url.build(submenuItem.action, {}, 'auto', false, submenuItem?.outsideMark ?? false)}
                                                    gtmTag={'menu-' + submenuItemTitle}
                                                >
                                                    {submenuItemTitle}
                                                </Link>
                                            )
                                    }

                                    {
                                        subsubmenuShown === submenuItemTitle
                                        && subsubmenuItems.length > 0
                                        && (
                                            <ul className="dropdown-menu">
                                                {
                                                    subsubmenuItems.map(subsubmenuItem => (
                                                        <li key={subsubmenuItem.title}>
                                                            <Link
                                                                to={Url.build(subsubmenuItem.action, {}, 'auto', false, subsubmenuItem?.outsideMark ?? false)}
                                                                gtmTag={'menu-' + subsubmenuItem.title}
                                                            >
                                                                {Translations.getStatic(subsubmenuItem.title)}
                                                            </Link>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        )
                                    }
                                </li>
                            );
                        })
                }
            </ul>
        );

    if (item.hasOwnProperty('url') === true) {
        if (isLocked === true) {
            menuLink = (
                <div
                    role="link"
                    onClick={() => props.openNotAllowedFeatureDialog({title, package: allowedInPackage})}
                    className="item__link"
                    onMouseEnter={() => setSubmenuShown(true)}
                    onMouseLeave={() => setSubmenuShown(false)}
                >
                    {title}
                    {isLocked === true && <Icon className="icon-upsell-star-invert" size="small"/>}
                    {submenu}
                </div>
            );
        } else {
            menuLink = (
                <a
                    href={item.url}
                    target="_self"
                    className="item__link"
                    title={title}
                    onMouseEnter={() => setSubmenuShown(true)}
                    onMouseLeave={() => setSubmenuShown(false)}
                >
                    {title}
                    {submenu}
                </a>
            );
        }
    } else if (item.hasOwnProperty('action') === true) {
        menuLink = (
            <Link
                to={Url.build(item.action, props.jobId === undefined ? {} : {job: props.jobId}, 'auto', false, item?.outsideMark ?? false)}
                className={'item__link' + (item.action === Url.getUrlParameter('action') ? ' active' : '')}
                title={title}
                onMouseEnter={() => setSubmenuShown(true)}
                onMouseLeave={() => setSubmenuShown(false)}
                gtmTag={'menu-' + title}
            >
                {icon}
                {title}
                {submenu}
            </Link>
        );
    } else if (item.hasOwnProperty('openModal') === true) {
        const onClickAction = isLocked === true
            ? () => props.openNotAllowedFeatureDialog({title, package: allowedInPackage})
            : () => props.openModal(item.openModal);

        menuLink = (
            <div role="link" onClick={onClickAction} className="item__link" onMouseEnter={() => setSubmenuShown(true)} onMouseLeave={() => setSubmenuShown(false)}>
                {title}
                {isLocked === true && <Icon className="icon-upsell-star-invert" size="small"/>}
                {submenu}
            </div>
        );
    } else if (item.hasOwnProperty('popup') === true && item.popup !== null) {
        menuLink = (
            <Popup
                children={item.popup}
                trigger={<span className="item__link disabled">{icon}{title}{submenu}</span>}
            />
        );
    } else {
        menuLink = (
            <div
                className="item__link"
                onMouseEnter={() => setSubmenuShown(true)}
                onMouseLeave={() => setSubmenuShown(false)}
            >
                <div className="item__link-title">
                    {title}
                    {submenu}
                </div>
            </div>
        );
    }

    return (
        <li
            id={item.hasOwnProperty('id') === true ? item.id : ''}
            className={'tab__item ' + (item.hasOwnProperty('class') ? item.class : '') + (Url.isItemActive(item) === true || isActive === true ? ' active' : '') + isLockedClassName}
        >
            {menuLink}
        </li>
    );
};

const mapDispatchToProps = {
    openModal,
    openNotAllowedFeatureDialog,
};

export default connect(
    null,
    mapDispatchToProps,
)(MenuItem);

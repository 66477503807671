// Job Ad Form 1st page:
export const JOB_TITLE_SECTION = 'job-title';
export const POSITION_DESCRIPTION_SECTION = 'position-description';
export const JOB_DESCRIPTION_SECTION = 'job-description';
export const POSITION_LOCATION_SECTION = 'position-location';
export const SALARY_SECTION = 'salary';
export const SALARY_OTHER_INFORMATION_SECTION = 'other-information-salary';
export const EDUCATION_SECTION = 'education';
export const LANGUAGES_SECTION = 'languages';
export const OTHER_SKILLS_SECTION = 'other-skills';
export const EXPERIENCE_SECTION = 'experience';
export const PERSONAL_SKILLS_SECTION = 'personal-skills';
export const SELECTION_PROCESS_INFO_SECTION = 'selection-process-info';
export const JOB_AD_SETTINGS_SECTION = 'job-ad-settings';
export const CONTACT_DETAILS_SECTION = 'contact-details';
export const JOB_TITLE = {
    //SECTION: 'job-title',
    JOB: 'job-title-input',
    LANGUAGE: 'select-language-job',
};
export const POSITION_DESCRIPTION = {
    //SECTION: 'position-description',
    DESC_INPUT: '[data-testid^="position-description"] input[type="text"]', //'[data-testid="selected-positions-input"]',
};
export const CONTRACT_TYPE = {
    FULL_TIME: 'input[value="full_time"]', //'[data-testid="full-time"]',
    CONTRACT: 'input[value="contract"]', //'[data-testid="contract"]',
};
export const STUDENTS = 'input[value="universityStudents"]'; //'[data-testid="valid-for-graduate"]',
export const JOB_DESCRIPTION = '[data-testid^="job-description"] .tiptap'; //'[data-testid="job-description"]',
export const POSITION_LOCATION = {
    //SECTION: 'position-location',
    DEFAULT: '[data-testid^="position-location"] input[type="radio"]', //:eq(2)' nahradit za '[data-testid="place-remote"]',
    UPDATE: '[data-testid^="position-location"] input[type="radio"]', //:eq(0)' nahradit za '[data-testid="place-onsite"]',
    INPUT: '[data-testid^="position-location"] input[type="text"]', //:eq(0) nahradit za '[data-testid="place-onsite-input"]',
    BTN: '[data-testid^="position-location"] button', //'[data-testid="start-date-btn"]',
    START_DATE: '[data-testid^="position-location"] input[type="text"]', //:eq(-1) nahradit za '[data-testid="start-date"]',
};
export const SALARY = {
    //SECTION: 'salary',
    DEFAULT: '[data-testid^="salary"] input[type="radio"]', //:eq(0)' nahradit za '[data-testid="salary-min-value"]',
    UPDATE: '[data-testid^="salary"] input[type="radio"]', //:eq(1)' nahradit za '[data-testid="salary-min-max-values"]',
    PUBLISH_MAX: '[data-testid^="salary"] input[type="checkbox"]', // '[data-testid="salary-publish-max-value"]',
};
export const SALARY_OTHER_INFORMATION = {
    //SECTION: 'other-information-salary',
    TEXTAREA: 'salary-other-information',
};
export const EDUCATION = {
    //SECTION: 'education',
    EDUCATION: `[data-testid^="education"] div[role="button"]`, //po implementovani data-testid, sa toto vymaze a v teste sa musi naparovat spravny variant
    //SECONDARY_WITHOUT_EXAMINATION: '[data-testid^="education"] div[role="button"]', //:eq(2)' nahradit za '[data-testid="education-secondary-without-examination"]',
    //SECONDARY_WITH_EXAMINATION: '[data-testid^="education"] div[role="button"]', //:eq(3)' nahradit za '[data-testid="education-secondary-with-examination"]',
    BTN: '[data-testid^="education"] button[type="button"]', //'[data-testid="education-btn"]',
    INPUT: '[data-testid^="education"] input[type="text"]', //'[data-testid="education-btn-input"]',
};
export const LANGUAGES = {
    //SECTION: 'languages',
    INPUT: `[data-testid^="languages"] input[type="text"]`, //:eq(0) nahradit za '[data-testid="languages-input"]',
    BTN: '[data-testid^="languages"] div[role="button"]', //'[data-testid="languages-btn"]',
};
export const OTHER_SKILLS = {
    //SECTION: 'other-skills',
    INPUT: `[data-testid^="other-skills"] input[type="text"]`, //'[data-testid="other-skills-input"]',
    BTN: '[data-testid^="other-skills"] div[role="button"]', //'[data-testid="other-skills-btn"]',
    ADD_DRIVER_LICENSE: '[data-testid^="other-skills"] button[type="button"]', //'[data-testid="other-skills-btn-driving-licence"]',
    CHECKBOX_OPTION: 'input[value="B"]', // '[data-testid="other-skills-btn-driving-licence-b"]',
};
export const EXPERIENCE = {
    //SECTION: 'experience',
    PRACTICE_YEARS_BTN: `[data-testid^="experience"] button`, //'[data-testid="practice-years-btn"]'
    PRACTICE_YEARS_INPUT: '[data-testid^="experience"] input[type="text"]', //:eq(0) nahradit za '[data-testid="practice-years-input"]'
    PRACTICE_BTN: '[data-testid^="experience"] button', // '[data-testid="practice-btn"]'
    PRACTICE_INPUT: '[data-testid^="experience"] input[type="text"]', //:eq(1) nahradit za '[data-testid="practice-input"]'
};
export const PERSONAL_SKILLS = {
    //SECTION: 'personal-skills',
    INPUT: `[data-testid^="personal-skills"] .tiptap`, //'[data-testid="other-personal-requirements"]'
};
export const SELECTION_PROCESS_INFO = {
    //SECTION: 'selection-process-info',
    TEXTAREA: 'recruitment-note',
    NUM_OF_OPENINGS_BTN: '[data-testid^="number-of-openings"] button', //'[data-testid="recruitment-number-of-opening-btn"]'
    NUM_OF_OPENINGS_INPUT: 'recruitment-number-of-opening-input',
    APPLICATE_TILL_BTN: '[data-testid^="applicable-till"] button', //'[data-testid="recruitment-applicate-till-btn"]'
    APPLICATE_TILL_INPUT: '[data-testid^="applicable-till"] input[type="text"]', //'[data-testid="recruitment-applicate-till-input"]'
};
export const JOB_AD_SETTINGS = {
    //SECTION: 'job-ad-settings',
    JOB_AD_WITHOUT_CV: '[data-testid="application-without-cv-stack"] input[type="checkbox"]', //'[data-testid="job-ad-without-cv"]'
    CVDB_OPTION: '[data-testid^="job-ad-settings"] input[value="0"]', //'[data-testid="job-ad-cvdb-agree"]'
    REF_NUM_BTN: '[data-testid^="job-ad-settings"] button[type="button"]', //'[data-testid="job-ad-ref-numb-btn"]'
    REF_NUM_INPUT: 'job-ad-ref-numb-input',
};
export const SUBMIT_JOB_AD_ONE_STEP_FORM = '.page-footer .ui button'; //:eq(-1) in one step form replace with '[data-testid="job-ad-one-step-form-submit"]',
export const MODAL_EDIT = {
    UPDATE_BTN: 'div.right.aligned.four.wide.column a', //'[data-testid="modal-edit-btn-update"]'
    UPDATE_JOB_TITLE: '.markModal.modal--right.modal--open input[placeholder="Napríklad: Mzdový účtovník"]', //'[data-testid="modal-edit-job-title-input"]'
    UPDATE_POSITION_DESCRIPTION: '.markModal.modal--right.modal--open input[placeholder="Začnite písať názov pozície"]', //'[data-testid="modal-edit-selected-positions-input"]'
    UPDATE_JOB_DESCRIPTION: '.markModal.modal--right.modal--open div.tiptap', //'[data-testid="modal-edit-job-description"]'
    UPDATE_POSITION_LOCATION: '[data-testid="position-location-section-wrapper"] [data-testid="inputs-wrapper-light"] input[type="radio"]', //'[data-testid="modal-edit-place-remote"]'
    UPDATE_SALARY_CHECKBOX: '[data-testid="salary-section-wrapper"] [data-testid="inputs-wrapper-light"] div div div input[type="radio"]', //'[data-testid="modal-edit-salary-checkbox"]'
    UPDATE_SALARY_INPUT: '[data-testid="salary-section-wrapper"] [data-testid="inputs-wrapper-light"] input[type="text"]', //'[data-testid="modal-edit-salary-min-input"]'
    UPDATE_LANG_CLOSE_BTN: '[data-testid="languages-section-wrapper"] [data-testid="inputs-wrapper-light"] [data-testid="icon-close"]', //'[data-testid="modal-edit-lang-btn-close"]'
    UPDATE_SUBMIT_BTN: '.markModal.modal--right.modal--open .modal__footer button.primary-button', //'[data-testid="modal-edit-btn-submit"]'
};
export const FOUR_STEPS_FORM = {
    SUBMIT_STEP_ONE: '.page-footer .ui button', //:eq(-1) in first step replace with '[data-testid="job-ad-submit-to-step-two"]',
    STEP_TWO: {
        TITLE: 'h3.mark-header', // :eq(1) in two step replace with '[data-testid="job-ad-title-in-step-two"]',
        SUBMIT_BTN: '.page-footer .ui button', //:eq(-1) in two step replace with '[data-testid="job-ad-submit-to-step-three"]',
    },
    STEP_THREE: {
        TITLE: 'h3.form-header', // :eq(1)in three step replace with '[data-testid="job-ad-title-in-step-three"]',
        SUBMIT_BTN: '.page-footer .ui button', //:eq(-1) in three step replace with '[data-testid="job-ad-submit-to-step-four"]',
    },
    STEP_FOUR: {
        TITLE: 'h3.form-header', // :eq(1) in four step replace with '[data-testid="job-ad-title-in-step-four"]',
        SUBMIT_BTN: '.page-footer .ui button', //:eq(-1) in four step replace with '[data-testid="job-ad-submit-to-step-five"]',
    },
    STEP_FIVE: {
        TITLE: 'div.create-job-container', // :eq(1) in five step replace with '[data-testid="job-ad-title-in-step-five"]',
        SUBMIT_BTN: '.page-footer .ui button', //:eq(-1) in five step replace with '[data-testid="job-ad-submit-job-ad-form"]',
    },
};
export const DETAIL_JOB = {
    DROPDOWN_BTN: '.extended-action__dropdown', //'[data-testid="detail-job-dropdown-btn"]',
    DROPDOWN_OPTION: '.extended-action__dropdown .ui.active.visible.dropdown div.extended-list div[role="option"] span', //'[data-testid="detail-job-dropdown-btn-option"]',
    BACK_BTN: 'div.square-button a[href="/mark/jobs"]', //'[data-testid="detail-job-back-btn"]'
};
export const JOBS_LIST = {
    RECORD: '.ui.list__jobs', //'[data-testid="jobs-list-record"]'
    JOB_TITLE_LINK: '.content_left a.header.ellipsis', //`a[href="/mark/pipeline/job/${jobId}"]` //'[data-testid="jobs-list-job-title-link-${jobId}"]'
    STATUS: 'span.circle.circle_green.mini', //'[data-testid="jobs-list-job-status"]'
    CLOSE_BTN: '.content_bottom span.float-right div[role="link"]', //'[data-testid="jobs-list-close-btn"]'
    CLOSE_SUBMIT_BTN: '.actions button.negative.secondary-button.red', //'[data-testid="jobs-list-close-btn-submit"]'
    NEW_JOB_AD_BTN: '.sub.header a[href="/mark/create-job-ad"]', //'[data-testid="jobs-list-btn-new-job-ad"]'
};
export const ALERT_SUCCESS = '.ui.green.small.success.message'; //'[data-testid="jobs-list-alert-success"],
export const STATISTICS = {
    TABLE_WITH_DATA: '[data-testid="job-statistics-content"]', //'[data-testid="statistics-table-with-data"]',
};

import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { Textarea } from '@profesia/adamui/components/common/input';
import { FORM_SALARY_OTHER_INFORMATION } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { SALARY_OTHER_INFORMATION } from '@profesia/mark-offer-form/codebooks/dataTestId';
const OtherInformation = () => {
    const t = useGetFixedT('salary.otherInformation');
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_SALARY_OTHER_INFORMATION, control });
    return (_jsx(Textarea, { label: t('label'), value: value || '', onChange: (value) => {
            setValue(FORM_SALARY_OTHER_INFORMATION, value);
        }, minHeight: 170, "data-testid": SALARY_OTHER_INFORMATION.TEXTAREA }));
};
export default OtherInformation;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Paper, styled } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import { useTranslation } from '@profesia/adamui/configuration';
import { OverlayLoader } from '../../loadings';
import Actions from './Actions';
const ModalContent = ({ header, title, closeButtonText, content, onClose, spacing = 4, hasActionButtons, overlayContent, showHeaderInModal = true, showFooterInModal = true, footer, isSaving = false, isSavingText = '', testId, }) => {
    const { t } = useTranslation();
    const headerContent = showHeaderInModal ? (_jsx(Actions, { onClose: onClose, closeButtonGridSize: 2, closeButtonText: closeButtonText, children: header ? (header) : (_jsx(Typography, { variant: "h3Medium", ml: 3, children: title })) })) : null;
    const modalFooterContent = showFooterInModal ? (!footer ? (_jsx(FormFooter, { rightSideActions: [
            {
                onClick: onClose,
                variant: 'secondary',
                label: closeButtonText ?? t('buttons.cancel'),
            },
        ] })) : (footer)) : null;
    return (_jsxs(ModalContentContainer, { variant: "outlined", children: [overlayContent, headerContent, _jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", overflow: "overlay", px: spacing, pt: spacing, mb: hasActionButtons ? 10.5 : 0, position: isSaving ? 'relative' : 'static', children: [content, _jsx(OverlayLoader, { description: isSavingText, isVisible: isSaving, testId: `${testId}-overlay-loader` })] }), modalFooterContent] }));
};
const ModalContentContainer = styled(Paper)(() => ({
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    iframe: {
        height: '100% !important',
        width: '100% !important',
    },
}));
export default ModalContent;

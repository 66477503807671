import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { openJob, closeJob, activateJob } from '../actions/JobDetailActions';
import PropTypes from 'prop-types';
import Translations from '../helpers/Translations';
import menuDotsImage from '../../img/icons/menu-dots.svg';
import Image from './Image';
import JobHelper from '../helpers/components/JobHelper';
import { openCloseJobUncontactedCandidateDialog } from '../actions/DialogActions';

const Header4MenuEllipsisButton = (props) => {
    const [isActiveDropdown, setIsActiveDropdown] = useState(false);

    const handleCloseJob = () => {
        props.closeJob(props.jobId);
        setIsActiveDropdown(true);
    };

    const handleOpenJob = () => {
        props.openJob(props.jobId);
    };

    const toggleActiveClass = () => {
        setIsActiveDropdown(!isActiveDropdown);
    };

    const { stateId, jobId, displaySize, isExternalVisitor } = props;

    const dropdownActive = isActiveDropdown === true && isExternalVisitor === false;

    const ExtendedButton = ({ toggleActiveClass, open, options }) => (
        <div onClick={toggleActiveClass} className={'extended-action menu action-menu ' + (dropdownActive === true ? 'active' : 'inactive')}>
            <div className="extended-action__icon-status">
                <Image size="tiny" src={menuDotsImage} />
            </div>

            <div className={'extended-action__dropdown'}>
                <Dropdown icon={{ className: 'hidden' }} trigger={displaySize.isMobile === false} open={open} onClick={toggleActiveClass} onClose={toggleActiveClass} direction={'left'}>
                    <Dropdown.Menu className="extended-list">
                        {options.map((option, i) => (
                            <Dropdown.Item key={i} {...option} className="txt-bold" />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );

    if (JobHelper.isPaid(stateId) === false) {
        return (
            <ExtendedButton
                stateId={stateId}
                status={Translations.getStatic('notActivatedJob')}
                open={dropdownActive}
                toggleActiveClass={toggleActiveClass}
                options={[
                    {
                        text: Translations.getStatic('activateJob'),
                        onClick: () => props.activateJob(jobId),
                    },
                ]}
            />
        );
    }

    if (JobHelper.isOpen(stateId) === true) {
        return (
            <ExtendedButton
                stateId={stateId}
                status={Translations.getStatic('openJob')}
                open={dropdownActive}
                toggleActiveClass={toggleActiveClass}
                options={[
                    {
                        text: Translations.getStatic('deleteJob'),
                        onClick: handleCloseJob,
                    },
                ]}
            />
        );
    }

    if (JobHelper.isClosed(stateId) === true) {
        return (
            <ExtendedButton
                stateId={stateId}
                status={Translations.getStatic('jobIsClosed')}
                open={dropdownActive}
                toggleActiveClass={toggleActiveClass}
                options={[
                    {
                        text: Translations.getStatic('activateJobs'),
                        onClick: handleOpenJob,
                    },
                ]}
            />
        );
    }

    return null;
};

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    openJob,
    closeJob,
    activateJob,
    openCloseJobUncontactedCandidateDialog,
};

Header4MenuEllipsisButton.propTypes = {
    isExternalVisitor: PropTypes.bool.isRequired,
    candidates: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header4MenuEllipsisButton);

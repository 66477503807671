import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { tabletDown } from '@profesia/adamui/styles/theme';
import SelectDefault from '../SelectDefault';
const SelectLanguage = ({ ...props }) => {
    return (_jsx(StyledSelectLanguage, { children: _jsx(SelectDefault, { ...props, labelPlacementLeft: true, size: "large" }) }));
};
const StyledSelectLanguage = styled(Stack)(({ theme: { palette, typography } }) => ({
    padding: 0,
    '.MuiBox-root': {
        marginRight: 10,
    },
    '.MuiInputBase-root': {
        backgroundColor: 'rgba(39, 116, 240, 0.08) !important',
        [tabletDown]: {
            maxWidth: 'fit-content',
        },
    },
    'fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: `${getColor(palette.primaryBlue)} !important`,
    },
    '.MuiInputBase-root:not(.Mui-disabled):hover': {
        backgroundColor: 'rgba(39, 116, 240, 0.16) !important',
    },
    '.MuiInputBase-root.Mui-focused': {
        backgroundColor: 'rgba(39, 116, 240, 0.16) !important',
    },
    '.MuiSelect-select .MuiBox-root': {
        alignItems: 'center',
        fontWeight: typography.fontWeightBold,
    },
    '.select-mobile-hidden': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        [tabletDown]: {
            display: 'none',
        },
    },
}));
export default SelectLanguage;

import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
const useOptionsState = (options, getOptions, onPopperCloseCallback, handleOpen, minimalInputLength) => {
    const [isOpen, setIsOpen] = useState(false);
    const [internalOptions, setInternalOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (options) {
            setInternalOptions(options);
        }
    }, [options]);
    const clearOptions = () => {
        if (debouncedGetAndSetOptions) {
            setInternalOptions(() => []);
        }
    };
    // In case that we want to use minimalInputLength, I had to make this workaround with event.target.value
    // The problem was, that at the time onPopperOpen method is fired, input in state is not yet updated.
    // Logic behind handleOpen therefore was calculating with input length before latest update.
    // I used Synthetic event to access ref to input, and accessed the input value directly.
    // Also I added if, to only do this is case, we use minimalInputLength.
    // TODO - If someone has an idea, how to do it in a nicer way, feel free to refactor this code.
    const onPopperOpen = (event) => {
        const value = event.target.value;
        getOptions && setIsLoading(true);
        setIsOpen(minimalInputLength ? handleOpen(value) : handleOpen());
    };
    const onPopperClose = (shouldCallBack) => {
        setIsOpen(false);
        if (shouldCallBack) {
            onPopperCloseCallback();
        }
        if (getOptions) {
            clearOptions();
        }
    };
    const debouncedGetAndSetOptions = useMemo(() => getOptions
        ? debounce(async (input) => {
            clearOptions();
            setIsLoading(true);
            setIsOpen(true);
            const options = await getOptions(input);
            setInternalOptions(options);
            setIsLoading(false);
        }, 500)
        : null, [getOptions]);
    return {
        onPopperOpen,
        onPopperClose,
        clearOptions,
        debouncedGetAndSetOptions,
        internalOptions,
        isOpen,
        isLoading,
    };
};
export default useOptionsState;

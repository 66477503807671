import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { CheckboxSquareTreeListIcon } from '@profesia/adamui/components/common/icons';
import TooltipWrapper from '@profesia/adamui/components/common/treeList/TooltipWrapper';
const TreeListControlSymbol = ({ hasSelectedOptions, selectedGroup, group, onGroupSelect, tooltipContent, }) => {
    let state = 'unchecked';
    let type = 'primary';
    const isSelected = selectedGroup === group;
    const isDisabled = Boolean(selectedGroup && !isSelected && !hasSelectedOptions);
    if (isSelected) {
        state = 'checked';
    }
    else {
        if (hasSelectedOptions) {
            state = 'checked';
            type = 'collapsible';
        }
    }
    const symbol = (_jsx(CheckboxSquare, { className: "jobPosition", state: state, type: type, width: 18, height: 18, onClick: (e) => {
            if (!selectedGroup || isSelected || hasSelectedOptions) {
                e.stopPropagation();
                onGroupSelect(hasSelectedOptions ? 'removeAll' : 'addAll');
            }
        } }));
    return isDisabled ? (_jsx(TooltipWrapper, { tooltipContent: tooltipContent, children: symbol })) : (symbol);
};
const CheckboxSquare = styled(CheckboxSquareTreeListIcon)(({ disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
}));
export default TreeListControlSymbol;

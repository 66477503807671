import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { Textarea } from '@profesia/adamui/components/common/input';
import { FORM_NOTE_FOR_CANDIDATE } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { SELECTION_PROCESS_INFO } from '@profesia/mark-offer-form/codebooks/dataTestId';
const NoteForCandidate = () => {
    const t = useGetFixedT('selectionProcessInfo.noteForCandidate');
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_NOTE_FOR_CANDIDATE, control });
    return (_jsx(Textarea, { label: t('label'), value: value, onChange: (value) => {
            setValue(FORM_NOTE_FOR_CANDIDATE, value);
        }, minHeight: 170, "data-testid": SELECTION_PROCESS_INFO.TEXTAREA }));
};
export default NoteForCandidate;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Backdrop as MuiBackdrop, Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { onBreakpoint } from '@profesia/adamui/styles/theme';
import Actions from './Modal/Actions';
import { ANIMATION_DURATION } from './Modal';
const MAIN_MODAL_WIDTH = 968;
const NESTED_MODAL_WIDTH = 704;
export const NestedModal = ({ className, header, content, mainContentIsOpen, nestedContentIsOpen, onClose = () => { }, nestedModalOnClose, nestedModal = null, overlayContent, dataCy = {}, shouldUseOffsetTop = true, }) => {
    const { dataCyMain, dataCyNested } = dataCy;
    return (_jsx(_Fragment, { children: _jsxs(Wrapper, { showMainContent: mainContentIsOpen, showNestedModal: nestedContentIsOpen, shouldUseOffsetTop: shouldUseOffsetTop, children: [nestedContentIsOpen ? _jsx(BackdropNested, { onClick: nestedModalOnClose, open: true }) : null, _jsxs(ContentWrapper, { children: [overlayContent, _jsx(MainContent, { "data-cy": dataCyMain, children: _jsxs(ModalStack, { className: className, children: [_jsx(Actions, { onClose: onClose, children: header }), content] }) }), _jsx(NestedContent, { "data-cy": dataCyNested, children: nestedModal })] })] }) }));
};
const ModalStack = styled(Stack)(() => ({
    height: '100%',
    outline: 'none',
    position: 'relative',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px',
}));
const BackdropNested = styled(MuiBackdrop)(() => ({
    zIndex: 1,
}));
const top = 69;
const Wrapper = styled(Box, {
    shouldForwardProp: (prop) => !['showMainContent', 'showNestedModal', 'shouldUseOffsetTop'].includes(prop),
})(({ showMainContent, showNestedModal, shouldUseOffsetTop, theme: { palette, spacing } }) => ({
    position: 'fixed',
    top: shouldUseOffsetTop ? top : 0,
    height: shouldUseOffsetTop ? `calc(100% - ${top}px)` : '100%',
    zIndex: 1299,
    transition: `all ${ANIMATION_DURATION}ms ease-in-out`,
    ...onBreakpoint('modalFullWidth', (() => {
        let right = '-100%';
        if (showMainContent) {
            right = '0px';
        }
        if (showNestedModal) {
            right = '100%';
        }
        return { right, width: '100vw' };
    })(), (() => {
        let right = showMainContent
            ? `-${NESTED_MODAL_WIDTH}px`
            : `calc(-1 * (${MAIN_MODAL_WIDTH}px + ${NESTED_MODAL_WIDTH}px))`;
        if (showNestedModal) {
            right = '0';
        }
        return { right };
    })()),
    '& .modal-content-actions': {
        bottom: 0,
    },
    '& .modal-detail .MuiGrid-root': {
        '& ul': {
            listStyle: 'none',
            paddingLeft: spacing(1),
            '& li': {
                position: 'relative',
                paddingLeft: 12,
                '&::before': {
                    content: '""',
                    display: 'inline-block',
                    position: 'absolute',
                    left: -8,
                    top: 3,
                    margin: 8,
                    width: 4,
                    height: 4,
                    backgroundColor: getColor(palette.primaryBlue),
                },
            },
        },
    },
}));
const ContentWrapper = styled(Box)(() => ({
    display: 'flex',
    height: '100%',
    width: '100%',
}));
const NestedContent = styled(Box)(({ theme: { palette } }) => ({
    position: 'relative',
    height: '100%',
    backgroundColor: getColor(palette.white),
    zIndex: 1299,
    ...onBreakpoint('modalFullWidth', {
        width: '100%',
        flex: '1 0 auto',
    }, {
        width: NESTED_MODAL_WIDTH,
    }),
}));
const MainContent = styled(Box)(({ theme: { palette } }) => ({
    height: '100%',
    backgroundColor: getColor(palette.white),
    ...onBreakpoint('modalFullWidth', {
        width: '100%',
        flex: '1 0 auto',
    }, {
        width: MAIN_MODAL_WIDTH,
    }),
}));
export default NestedModal;

import { jsx as _jsx } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import Loader from './Loader';
const OverlayLoader = ({ description, isVisible, testId = 'overlay-loader', }) => {
    if (!isVisible)
        return null;
    return (_jsx(OverlayContainer, { "data-testId": testId, children: _jsx(Loader, { description: description }) }));
};
const OverlayContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: 'white',
}));
export default OverlayLoader;

import Translations from '../Translations';
import Constants from '../Constants';
import DateFormatted from '../DateFormatted';

export default class CandidateHistoryHelper {
    static get getCandidateHistoryFilterOptions() {
        return [
            {
                key: 1,
                label: Translations.getStatic('allEvents'),
                id: 'allEvents',
            },
            {
                key: 2,
                label: Translations.getStatic('editStage'),
                id: 'editStage',
            },
            {
                key: 3,
                label: Translations.getStatic('emailSending'),
                id: 'emailSending',
            },
            {
                key: 4,
                label: Translations.getStatic('emailFromCandidate'),
                id: 'emailFromCandidate',
            },
            {
                key: 5,
                label: Translations.getStatic('colleagueForwarding'),
                id: 'colleagueForwarding',
            },
            {
                key: 6,
                label: Translations.getStatic('notesAndTags'),
                id: 'notesAndTags',
            },
            {
                key: 7,
                label: Translations.getStatic('events'),
                id: 'events',
            },
            {
                key: 8,
                label: Translations.getStatic('attachments'),
                id: 'attachments',
            },
            {
                key: 9,
                label: Translations.getStatic('dataChanges'),
                id: 'basicData',
            },
        ];
    }

    static showHistoryBulkAction(bulkAction, selectedEvent) {
        if (selectedEvent === this.getCandidateHistoryFilterOptions[0].id) {
            return true;
        }

        let filterOption = '';

        switch (bulkAction.type) {
            case 'tag':
            case 'note':
                filterOption = 'notesAndTags';
                break;

            case 'stage':
            case 'copy':
            case 'state':
                filterOption = 'editStage';
                break;

            case 'event':
                filterOption = 'events';
                break;

            case 'email':
                if (bulkAction.value !== null && bulkAction.value.emailType === Constants.EMAIL_TYPE_FROM_CANDIDATE) {
                    filterOption = 'emailFromCandidate';
                } else if (bulkAction.value !== null && bulkAction.value.emailType === Constants.EMAIL_TYPE_TO_COLLEAGUE) {
                    filterOption = 'colleagueForwarding';
                } else {
                    filterOption = 'emailSending';
                }
                break;

            case 'scheduledEmail':
                if (bulkAction.value !== null && bulkAction.value.scheduledEmail.emailType === Constants.EMAIL_TYPE_TO_COLLEAGUE) {
                    filterOption = 'colleagueForwarding';
                } else {
                    filterOption = 'emailSending';
                }
                break;

            case 'attachment':
                filterOption = 'attachments';
                break;

            case 'basicData':
                filterOption = 'basicData';
                break;
        }

        return selectedEvent === filterOption;
    }

    static showHistoryItem(historyItem, selectedEvent) {
        if (selectedEvent === this.getCandidateHistoryFilterOptions[0].id) {
            return true;
        }

        let show = false;

        historyItem.bulkActions.forEach(bulkAction => {
            if (this.showHistoryBulkAction(bulkAction, selectedEvent) === true) {
                show = true;
            }
        });

        return show;
    }

    /**
     *
     * @param item
     * @param candidate
     */
    static getHistoryItemHeader(item, candidate) {
        if (candidate !== undefined
            && item.bulkActions.length === 1
            && item.bulkActions[0].hasOwnProperty('isFirst') === true
            && item.bulkActions[0].isFirst === true
            && item.bulkActions[0].type === 'stage'
            && candidate.source.id === Constants.SOURCE_ID_RECOMMENDATION_FORM
            && candidate.hasOwnProperty('recommendation') === true
            && candidate.recommendation !== null
        ) {
            return Translations.getStatic('recommendation')
                + ' - '
                + candidate.recommendation.name;
        }

        let createdByName = '';

        if (candidate !== null
            && item.bulkActions[0].type === Constants.HISTORY_TYPE_EMAIL
            && item.bulkActions[0].value !== null
            && item.bulkActions[0].value.emailType === Constants.EMAIL_TYPE_FROM_CANDIDATE
        ) {
            createdByName = ' - ' + (candidate?.candidateName ?? Translations.getStatic('nameNotSet'));
        } else if (item.createdBy !== null) {
            createdByName = ' - ' + item.createdBy.name;
        }

        return DateFormatted.getDateTime(item.createdAt, 'j.n.Y ')
            + Translations.getStatic('at')
            + DateFormatted.getDateTime(item.createdAt, ' H:i')
            + createdByName;
    }
}

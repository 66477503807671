export const REQUEST_JOB_ORDER_TIERS = 'REQUEST_JOB_ORDER_TIERS';
export const RECIEVE_JOB_ORDER_TIERS = 'RECIEVE_JOB_ORDER_TIERS';
export const REQUEST_JOB_ORDER_ADDITIONAL_SERVICES = 'REQUEST_JOB_ORDER_ADDITIONAL_SERVICES';
export const RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES = 'RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES';
export const SET_JOB_ORDER_SELECTED_TIER = 'SET_JOB_ORDER_SELECTED_TIER';

export const requestOrderTiers = () => ({
    type: REQUEST_JOB_ORDER_TIERS,
});

export const recieveOrderTiers = (data) => ({
    type: RECIEVE_JOB_ORDER_TIERS,
    payload: data
});

export const requestAdditionalServices = () => ({
    type: REQUEST_JOB_ORDER_ADDITIONAL_SERVICES,
});

export const recieveAdditionalServices= (data) => ({
    type: RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES,
    payload: data
});

export const setSelectedTier = tierId => ({
    type: SET_JOB_ORDER_SELECTED_TIER,
    payload: tierId,
});

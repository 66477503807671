import React from 'react';
import { connect } from 'react-redux';
import Image from './Image';
import world from '../../img/icons/world.svg';
import Translations from '../helpers/Translations';

const Header4MenuPublishPositionButton = ({ displaySize }) => (
    <div className="extended-action">
        <div className="extended-action__icon-status">
            <Image size="tiny" src={world} />
        </div>
        {!displaySize.isMobile && <div className="position-publish-button">{Translations.getStatic('publishPosition')}</div>}
    </div>
);

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
});

export default connect(mapStateToProps, null)(Header4MenuPublishPositionButton);

import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { FormProvider as FormProviderRHF, useForm } from 'react-hook-form';
import useGetResolver from '@profesia/mark-offer-form/components/content/form/resolver';
import getSetValue from '@profesia/mark-offer-form/components/content/form/getSetValue';
import { useUpdateCodebookTranslations } from '@profesia/mark-offer-form/services/updateCodebookTranslations/useUpdateCodebookTranslations';
import useScrollToFirstError from '@profesia/mark-offer-form/components/content/form/useScrollToFirstError';
import { ValidationSchema } from '@profesia/mark-offer-form/components/content/form/types';
const LEAVE_PAGE_EVENT = 'beforeunload';
const FormProvider = ({ data, children }) => {
    const resolver = useGetResolver(ValidationSchema.Job);
    const methods = useForm({
        resolver: resolver,
        defaultValues: data,
    });
    const { setValue: setFormValue, getValues, formState, clearErrors } = methods;
    const { errors, isDirty } = formState;
    const setValue = getSetValue(setFormValue, clearErrors, errors);
    useScrollToFirstError(errors);
    useUpdateCodebookTranslations(setValue, getValues);
    const onBeforeUnload = (event) => {
        if (isDirty) {
            event.preventDefault();
        }
    };
    useEffect(() => {
        addEventListener(LEAVE_PAGE_EVENT, onBeforeUnload);
        return () => {
            removeEventListener(LEAVE_PAGE_EVENT, onBeforeUnload);
        };
    }, [isDirty]);
    return (_jsx(FormProviderRHF, { ...methods, setValue: setValue, children: children }));
};
export default FormProvider;

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MarkOfferForm } from '@profesia/mark-offer-form';
import { Steps } from '@profesia/mark-offer-form/types';
import { FormRef } from '@profesia/mark-offer-form/components/content/form/types';
import { configure } from '@profesia/mark-offer-form/configuration';
import OverlayLoader from '@profesia/adamui/components/common/loadings/OverlayLoader';
import LoadingComponent from '../LoadingComponent';
import { closeModal, closeModalSecond, closeModalThird, openModalSecond, openModalThird } from '../../actions/ModalActions';
import MarkModal from '../Modal/MarkModal';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';
import { Languages } from '@profesia/mark-offer-form/i18n';
import { getTranslations, useTranslations } from '../Job/JobAdvertisement/helpers';
import { useEditFormData } from '../Job/JobAdvertisement/getFormData';
import getJobTitleAiSuggestions from '../Job/JobAdvertisement/getJobTitleAiSuggestions';
import generateTextWithAi from '../Job/JobAdvertisement/generateTextWithAi';
import getCodebooks from '../Job/JobAdvertisement/getCodebooks';
import getBusinessAreas from '../Job/JobAdvertisement/getBusinessAreas';
import getOtherSkills from '../Job/JobAdvertisement/getOtherSkills';
import { RootState } from '../../stores/types';
import Url from '../../helpers/Url';
import { DisplaySizeType } from '../../../interfaces/DisplaySizeType';
import postForm from '../Job/JobAdvertisement/postForm';
import updateForm from '../Job/JobAdvertisement/updateForm';
import getContactPersons from '../Job/JobAdvertisement/getContactPersons';
import updateContactPerson from '../Job/JobAdvertisement/updateContactPerson';
import updateCompanyDetails from '../Job/JobAdvertisement/updateCompanyDetails';

const EditJobAdFormModal = () => {
    const dispatch = useDispatch();
    const isSavingJob = useSelector((state: RootState) => state.isSavingJob);
    const editFormData = useEditFormData();
    const poistionId = useSelector((state: RootState) => state.jobModel.job?.id);
    const isOpen = useSelector((state: RootState) => state.modalLogic.isOpen);
    const actionSuccessStatus = useSelector((state: RootState) => state.actionSuccessStatus);
    const displaySize = useSelector((state: { displaySize: DisplaySizeType }) => state.displaySize);
    const previousActionSuccessStatusRef = useRef<boolean>();
    const translations = useTranslations();
    const formRef = useRef<FormRef>(null);
    const currentLanguage = Language.get() as Languages;

    useEffect(() => {
        configure({
            api: {
                getJobTitleAiSuggestions,
                generateTextWithAi,
                getCodebooks,
                getBusinessAreas,
                getTranslations,
                getOtherSkills,
                postForm,
                updateForm: (data) => updateForm(data, poistionId, dispatch),
                getContactPersons,
                updateContactPerson: (data) => updateContactPerson(data, dispatch),
                updateCompanyDetails: (data) => updateCompanyDetails(data, dispatch),
            },
            form: {
                openModalSecond: () => {
                    dispatch(openModalSecond(null));
                },
                openModalThird: () => {
                    dispatch(openModalThird(null));
                },
                closeModalSecond: () => {
                    dispatch(closeModalSecond());
                },
                closeModalThird: () => {
                    dispatch(closeModalThird());
                },
            },
        });
    }, []);

    useEffect(() => {
        if (previousActionSuccessStatusRef.current !== undefined && actionSuccessStatus) {
            dispatch(closeModal());
        }
        previousActionSuccessStatusRef.current = actionSuccessStatus;
    }, [actionSuccessStatus, dispatch]);

    const handleSubmit = () => {
        if (formRef.current?.handleSubmit) {
            formRef.current?.handleSubmit();
        }
    };

    return (
        <MarkModal
            isOpen={isOpen}
            title={Translations.getStatic('editInfoAboutJob')}
            closeModal={() => {
                if (!formRef.current?.isDirty || window.confirm(Translations.getStatic('dialogUnsavedChanges'))) {
                    dispatch(closeModal());
                }
            }}
            wide={displaySize.isDesktop}
            actionButton={{
                visible: true,
                text: Translations.getStatic('save'),
                loading: isSavingJob,
                disabled: isSavingJob,
                onClick: handleSubmit,
            }}
            content={
                !translations ? (
                    <LoadingComponent text={Translations.getStatic('loading')} />
                ) : (
                    <>
                        <OverlayLoader isVisible={isSavingJob} description={Translations.getStatic('saving')} testId="edit-offer-form-modal-overlay-loader" />
                        <MarkOfferForm ref={formRef} currentStep={Steps.positionDescription} isEditMode={true} data={editFormData} translations={translations} language={currentLanguage} googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY_FRONTEND as string} isExtendedFormVisible={!Url.isOnlineOrMaster()} />
                    </>
                )
            }
        />
    );
};

export default EditJobAdFormModal;

import React from 'react';
import Icon from './Icon';
import Translations from '../helpers/Translations';
import DateFormatted from '../helpers/DateFormatted';

interface Offer {
    isActive?: boolean;
    isApproved?: boolean;
    createdAt?: string;
    validTill?: string;
}

interface Status {
    className: string;
    text: string;
    icon?: JSX.Element;
}

interface Header4MenuJobStatusProps {
    offer: Offer | null; 
}

const Header4MenuJobStatus = ({ offer }: Header4MenuJobStatusProps): JSX.Element => {
    const getStatus = (): Status => {
        if (offer) {
            if (!offer.isApproved) {
                return {
                    className: 'yellow',
                    text: Translations.getStatic('verificationAwait'),
                    icon: <Icon className="icon-time" />,
                };
            }
            if (offer.isActive) {
                return {
                    className: 'green',
                    text: `${Translations.getStatic('activeOffer')} ${DateFormatted.getDate(offer.createdAt!)} - ${DateFormatted.getDate(offer.validTill!)}`,
                };
            }
        }

        return { className: 'prussian', text: Translations.getStatic('notActiveOffer') };
    };

    const status = getStatus();

    return (
        <div className="job-status">
            <span className={`job-status-tag ${status.className}`}>
                {status.icon}
                {status.text}
            </span>
        </div>
    );
};

export default Header4MenuJobStatus;

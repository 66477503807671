import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled, Box, alpha } from '@mui/material';
import { Radio } from '@profesia/adamui/components/common/input/radioButtons';
import { getColor } from '@profesia/adamui/styles/colors';
import { chainClassNames, getMuiErrorClass, MUI_ERROR_CLASS, } from '@profesia/adamui/styles/classes';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
const CardMultipleChoice = ({ item, description, meta, isSelected, onClick, children, hasError, showErrorInTitle, className, }) => (_jsxs(StyledContainer, { isSelected: isSelected, onClick: !isSelected ? onClick : undefined, className: chainClassNames([getMuiErrorClass(hasError), className]), children: [_jsx(Box, { children: _jsx(Radio, { variant: "circle", checked: isSelected, onClick: onClick }) }), _jsx(Box, { display: "flex", flex: "1 1 auto", children: _jsxs(Stack, { pb: 1, justifyContent: "center", flex: "1 1 auto", children: [_jsx(StyledHeaderContainer, { children: _jsx(TypographySpan, { weight: "bold", color: showErrorInTitle && hasError ? 'secondaryRed' : 'primaryNavy', children: item }) }), _jsx(TypographySpan, { color: "primaryNavy", pt: 0.5, children: description }), isSelected && (_jsx(Box, { width: 9 / 10, pt: children ? 1.5 : 0, children: children })), meta && _jsx(Box, { pt: 2, children: meta })] }) })] }));
const StyledHeaderContainer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(1.5),
    '& .MuiTypography-root': {
        margin: 'auto 0',
    },
}));
const StyledContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme: { palette: { primaryBlue, neutralDark4, white, secondaryRed }, spacing, }, isSelected, }) => ({
    display: 'flex',
    padding: spacing(1.875, 2.5, 1.125, 2),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: isSelected ? getColor(primaryBlue) : alpha(getColor(neutralDark4), 0.4),
    backgroundColor: isSelected ? alpha(getColor(primaryBlue), 0.08) : getColor(white),
    borderRadius: 4,
    cursor: isSelected ? 'default' : 'pointer',
    '&:hover': {
        borderColor: isSelected ? getColor(primaryBlue) : alpha(getColor(neutralDark4), 0.8),
    },
    [`&.${MUI_ERROR_CLASS}`]: {
        borderColor: getColor(secondaryRed),
    },
}));
export default CardMultipleChoice;

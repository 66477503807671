import { jsx as _jsx } from "react/jsx-runtime";
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material';
import { getSvgIconClassName } from '@profesia/adamui/utils/testHelpers';
const NewIcon = styled(SvgIcon, {
    shouldForwardProp: (prop) => !['width', 'height', 'fill'].includes(prop),
})(({ width, height, fill }) => {
    return {
        '&.MuiSvgIcon-root': {
            height: height ?? 'auto',
            width: width ?? 'auto',
            fill,
            cursor: 'pointer',
        },
    };
});
const createSvgIcon = (path, name, svgIconDefaultProps = {}) => {
    const NewIconPropsWrapper = (props) => {
        const { completed, active, error, ...otherProps } = props;
        const transformedProps = {
            ...otherProps,
            completed: completed ? completed.toString() : undefined,
            active: active ? active.toString() : undefined,
            error: error ? error.toString() : undefined,
        };
        return (_jsx(NewIcon, { className: getSvgIconClassName(name), ...svgIconDefaultProps, ...transformedProps, "data-testid": `icon-${name.toLowerCase()}`, children: path }));
    };
    return NewIconPropsWrapper;
};
export default createSvgIcon;

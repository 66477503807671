import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { useGetJobTitleAiSuggestions } from '@profesia/mark-offer-form/services';
import { trackEvent, ComponentTypeEnum } from '@profesia/mark-offer-form/utils/track/track';
import { FEATURE_CANCEL_AI, FEATURE_REWRITE_AI, FEATURE_USE_AI, LOCATION_AI_WIZARD, NAME_JOB_TITLE, } from '@profesia/mark-offer-form/utils/track/constants';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import Modal from '@profesia/adamui/components/common/modal/Modal';
import Suggestions from './Suggestions';
import Loading from './Loading';
const AiModal = ({ jobTitle, onInsert, onClose, isOpen }) => {
    const t = useTranslationInPageLanguage('jobTitle.aiModal');
    const tCommon = useTranslationInPageLanguage('common');
    const addToast = useToastWithTranslation();
    const { isEditMode } = useOptionsContext();
    const [selectedSuggestion, setSelectedSuggestion] = useState('');
    const [selectedTone, setSelectedTone] = useState('');
    const { data: suggestions, isFetching, refetch, isError, } = useGetJobTitleAiSuggestions(jobTitle, isOpen);
    useEffect(() => {
        if (isError) {
            addToast({ message: 'common.responseError.jobTitleAiSuggestions', variant: 'error' });
            if (isEditMode) {
                onCloseInternal();
            }
        }
    }, [isError, isEditMode]);
    const resetSelectedSuggestion = () => {
        setSelectedSuggestion('');
        setSelectedTone('');
    };
    const onCloseInternal = () => {
        resetSelectedSuggestion();
        onClose();
    };
    return (_jsx(Modal, { isOpen: isOpen, shouldUseOffsetTop: false, footer: _jsx(FormFooter, { leftSideActions: [
                {
                    onClick: async () => {
                        trackEvent(LOCATION_AI_WIZARD, NAME_JOB_TITLE, ComponentTypeEnum.buttonModal, null, FEATURE_REWRITE_AI);
                        resetSelectedSuggestion();
                        await refetch();
                    },
                    variant: 'primary',
                    label: t('generateNewSuggestions'),
                },
            ], rightSideActions: [
                {
                    onClick: () => {
                        trackEvent(LOCATION_AI_WIZARD, NAME_JOB_TITLE, ComponentTypeEnum.buttonModal, null, FEATURE_CANCEL_AI);
                        onCloseInternal();
                    },
                    variant: 'secondary',
                    label: t('cancel'),
                },
                {
                    onClick: () => {
                        trackEvent(LOCATION_AI_WIZARD, NAME_JOB_TITLE, ComponentTypeEnum.buttonModal, selectedTone, FEATURE_USE_AI);
                        onInsert(selectedSuggestion);
                        onCloseInternal();
                    },
                    variant: 'primary',
                    label: t('applySuggestion'),
                    disabled: !selectedSuggestion,
                },
            ], variant: "neutralLight0" }), content: _jsx(_Fragment, { children: !isFetching && suggestions ? (_jsx(Suggestions, { userValue: jobTitle, suggestions: suggestions, selectedTone: selectedTone, onSuggestionClick: (value, tone) => {
                    setSelectedSuggestion(value);
                    setSelectedTone(tone);
                } })) : (_jsx(Loading, {})) }), onClose: () => {
            onCloseInternal();
        }, title: t('title'), closeButtonText: tCommon('actions.cancel') }));
};
export default AiModal;

import React from 'react';
import { connect } from 'react-redux';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import Image from './Image';
import edit from '../../img/icons/edit.svg';
import { openModal } from '../actions/ModalActions';
import Translations from '../helpers/Translations';
import DateFormatted from '../helpers/DateFormatted';

const Header4MenuPositionDetailButton = ({ displaySize, openModal, updatedAt, addDate, isLoading }) => {
    if (isLoading) {
        return (
            <div className="extended-action inverted">
                <SkeletonRectangular width={97} height={18} borderRadius={26} />
            </div>
        );
    }

    const { isMobile } = displaySize;

    const isUpdateLaterThanAdd = (addDate, updatedAt) => {
        if (!updatedAt) {
            return false;
        }

        const addDateObj = new Date(addDate);
        const updatedAtObj = new Date(updatedAt);

        return updatedAtObj > addDateObj;
    };

    return (
        <div onClick={() => openModal('EditJobAdFormModal')} className="extended-action">
            <div className="extended-action__icon-status">
                <Image size="tiny" src={edit} />
            </div>
            {!isMobile && (
                <div className="position-detail-button">
                    {Translations.getStatic('editJobInfo')}
                    {isUpdateLaterThanAdd(addDate, updatedAt) && (
                        <p className="txt-black-light text-small">
                            {Translations.getStatic('lastTime')} {DateFormatted.getDate(updatedAt)}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
    updatedAt: state.jobModel.job.updatedAt,
    addDate: state.jobModel.job.addDate,
});

const mapDispatchToProps = {
    openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header4MenuPositionDetailButton);

import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import ImageCropper from '@profesia/adamui/components/common/ImageCropper/index';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import Modal from '@profesia/adamui/components/common/modal/Modal';
import { useLogoContext } from '@profesia/mark-offer-form/services/LogoContext';
const EditLogoModal = ({ isOpen, onClose, isModalSecond }) => {
    const commonT = useTranslationInPageLanguage('common');
    const t = useTranslationInPageLanguage('companyDetails.editLogoModal');
    const formData = useForm();
    const { base64Logo, setBase64Logo, setIsEdited } = useLogoContext();
    const [editedLogo, setEditedLogo] = useState(null);
    const onUse = () => {
        setBase64Logo(editedLogo);
        setIsEdited(true);
        onClose();
    };
    const onDelete = () => {
        setBase64Logo(null);
        setEditedLogo(null);
        setIsEdited(false);
        onClose();
    };
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, spacing: 4, isModalSecond: isModalSecond, title: t('title'), closeButtonText: commonT('actions.cancel'), shouldUseOffsetTop: false, footer: _jsx(FormFooter, { leftSideActions: [
                {
                    label: t('deleteLogo'),
                    variant: 'destructive',
                    onClick: onDelete,
                },
            ], submitAction: onUse, submitButtonText: t('useButton'), onClose: onClose, closeButtonText: t('cancelButton') }), content: _jsx(FormProvider, { ...formData, children: _jsx(ImageCropper, { imageSrc: base64Logo || '', mainLabel: t('companyLogo'), previewLabel: t('preview'), aspect: 2.4, onCropChange: (base64) => setEditedLogo(base64), imgFormat: "jpeg", maxCropperBoxHeight: 264, maxPreviewBoxHeight: 100 }) }) }));
};
export default EditLogoModal;

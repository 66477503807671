import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, styled } from '@mui/material';
import { tabletDown } from '@profesia/adamui/styles/theme';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT, useGetLocale, useTranslationInPageLanguage, } from '@profesia/mark-offer-form/i18n';
import { FORM_JOB_TITLE } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import Tooltip from '@profesia/mark-offer-form/components/content/common/Tooltip';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { getErrorAndScrollClass } from '@profesia/mark-offer-form/components/content/form';
import { trackEvent, ComponentTypeEnum } from '@profesia/mark-offer-form/utils/track/track';
import { LOCATION_AI_WIZARD, NAME_JOB_TITLE, } from '@profesia/mark-offer-form/utils/track/constants';
import { isAiActiveForLanguage } from '@profesia/mark-offer-form/services/ai';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { JOB_TITLE_MAX_LENGTH } from '@profesia/mark-offer-form/components/content/form/constants';
import { JOB_TITLE_SECTION, JOB_TITLE } from '@profesia/mark-offer-form/codebooks/dataTestId';
import AiModal from '../Modal';
import LanguageDropdown from '../LanguageDropdown';
import AiButton from '../AiButton';
import { Input, Stack } from './Styled';
const Content = ({ setIsHintActive }) => {
    const maxLength = JOB_TITLE_MAX_LENGTH;
    const { form } = getConfiguration();
    const [isOpen, setIsOpen] = useState(false);
    const t = useGetFixedT('jobTitle');
    const currentLanguage = useGetLocale();
    const tPage = useTranslationInPageLanguage('jobTitle');
    const { isEditMode } = useOptionsContext();
    const { setValue, control, formState: { errors }, } = useFormContext();
    const value = useWatch({ name: FORM_JOB_TITLE, control });
    const isEmpty = !Boolean(value);
    const [bottomLabel = tPage('bottomLabel', { count: maxLength - (value?.length ?? 0) }), scrollToErrorClass,] = getErrorAndScrollClass(errors.jobTitle);
    const isAiActive = isAiActiveForLanguage(currentLanguage);
    return (_jsxs(_Fragment, { children: [_jsx(StyledBoxedSectionWrapper, { title: t('title'), className: scrollToErrorClass, endComponent: _jsx(LanguageDropdown, {}), isEditMode: isEditMode, testId: JOB_TITLE_SECTION, children: _jsxs(Stack, { display: "flex", flexDirection: "row", gap: 1, isAiActive: isAiActive, children: [_jsx(Input, { onMouseOver: () => setIsHintActive(true), onMouseLeave: () => setIsHintActive(false), onChange: (e) => {
                                setValue(FORM_JOB_TITLE, e.target.value);
                            }, inputProps: { maxLength, 'data-testid': JOB_TITLE.JOB }, value: value, height: 48, bottomLabel: bottomLabel, placeholder: t('placeholder'), isError: Boolean(scrollToErrorClass) }), isAiActive ? (_jsx(Tooltip, { tooltipContent: tPage(`aiButton.tooltip.${isEmpty ? 'disabled' : 'enabled'}`), variant: "content", placement: "top-start", children: _jsx(Box, { children: _jsx(AiButton, { handleClick: () => {
                                        trackEvent(LOCATION_AI_WIZARD, NAME_JOB_TITLE, ComponentTypeEnum.buttonField);
                                        setIsOpen(true);
                                        if (isEditMode) {
                                            form.openModalSecond();
                                        }
                                    }, isDisabled: isEmpty }) }) })) : null] }) }), _jsx(AiModal, { jobTitle: value, onInsert: (newValue) => {
                    if (newValue) {
                        setValue(FORM_JOB_TITLE, newValue);
                    }
                }, onClose: () => {
                    setIsOpen(false);
                    if (isEditMode) {
                        form.closeModalSecond();
                    }
                }, isOpen: isOpen })] }));
};
const StyledBoxedSectionWrapper = styled(BoxedSectionWrapper, {
    shouldForwardProp: (prop) => prop !== 'isEditMode',
})(({ theme: { spacing }, isEditMode }) => ({
    ...(isEditMode && {
        [tabletDown]: {
            paddingTop: spacing(2),
        },
    }),
}));
export default Content;

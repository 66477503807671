import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Modal as MuiModal, Slide, Stack } from '@mui/material';
import { ModalContent } from '@profesia/adamui/components/common/modal';
export const ANIMATION_DURATION = 350;
const Modal = ({ className, variant = 'modal', isOpen, onClose, header = null, title, closeButtonText, content, hasActionButtons = true, overlayContent, spacing = 0, showHeaderInModal = true, showFooterInModal = true, isModalSecond = false, isModalSecondClosing = false, footer = null, dataCy, shouldUseOffsetTop = true, isSaving = false, isSavingText, testId = 'modal', ...otherProps }) => {
    return (_jsx(MuiModal, { className: `variant-${variant}${className ? ` ${className}` : ''} ${shouldUseOffsetTop ? 'topOffset' : 'noTopOffset'}`, open: isOpen, onClose: (event, reason) => {
            if (reason !== 'backdropClick') {
                onClose();
            }
        }, closeAfterTransition: true, "data-testId": testId, ...otherProps, children: _jsx(Slide, { direction: "left", timeout: { enter: ANIMATION_DURATION, exit: ANIMATION_DURATION }, in: isOpen, children: _jsx(Box, { className: `${isModalSecond ? ' modal-second' : ''}${isModalSecondClosing ? ' modal-second-closing' : ''}`, "data-cy": dataCy?.dataCyMain, children: _jsx(Stack, { className: `variant-${variant}-content`, children: _jsx(ModalContent, { showHeaderInModal: showHeaderInModal, showFooterInModal: showFooterInModal, overlayContent: overlayContent, onClose: onClose, header: header, title: title, closeButtonText: closeButtonText, content: content, spacing: spacing, hasActionButtons: hasActionButtons, footer: footer, isSaving: isSaving, isSavingText: isSavingText, testId: testId }) }) }) }) }));
};
export default Modal;

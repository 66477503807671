import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormProvider, useForm } from 'react-hook-form';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import Modal from '@profesia/adamui/components/common/modal/Modal';
import ModalWarningNotificationBox from '@profesia/mark-offer-form/components/content/form/components/ModalWarningNotificationBox';
import { useContactPersonUpdate } from '@profesia/mark-offer-form/services/contactPersons/useContactPersonUpdate/index';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
import useGetResolver from '@profesia/mark-offer-form/components/content/form/resolver';
import { ValidationSchema } from '@profesia/mark-offer-form/components/content/form/types';
import getSetValue from '@profesia/mark-offer-form/components/content/form/getSetValue';
import ContactForm from '../ContactForm';
const ContactModal = ({ contactPerson, isOpen, onSubmit, onClose }) => {
    const pageT = useTranslationInPageLanguage('contactDetails');
    const buttonsT = useTranslationInPageLanguage('buttons');
    const commonT = useTranslationInPageLanguage('common');
    const resolver = useGetResolver(ValidationSchema.ContactPerson);
    const addToast = useToastWithTranslation();
    const onSuccess = (data) => {
        onSubmit(data);
        addToast({ message: 'common.responseSuccess.saveDataSuccess', variant: 'success' });
    };
    const onError = () => addToast({ message: 'common.responseError.saveDataFailed', variant: 'error' });
    const { mutate: updateContactDetails, isPending } = useContactPersonUpdate(onSuccess, onError);
    const form = useForm({
        resolver: resolver,
        defaultValues: {
            contactPerson: contactPerson,
        },
    });
    const { setValue: setFormValue, formState, clearErrors, handleSubmit } = form;
    const { errors } = formState;
    const setFormProviderValue = getSetValue(setFormValue, clearErrors, errors);
    const onFormSubmit = (data) => {
        updateContactDetails({ id: contactPerson.id, ...data.contactPerson });
    };
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, spacing: 4, title: pageT('contactModal.title'), closeButtonText: commonT('actions.cancel'), shouldUseOffsetTop: false, footer: _jsx(FormFooter, { isLoading: isPending, submitAction: handleSubmit(onFormSubmit), submitButtonText: buttonsT('save'), onClose: onClose, closeButtonText: buttonsT('cancel') }), content: _jsxs("div", { children: [_jsx(ModalWarningNotificationBox, { children: pageT('info.description') }), _jsx(FormProvider, { ...form, setValue: setFormProviderValue, children: _jsx(ContactForm, {}) })] }), isSaving: isPending, isSavingText: commonT('saving'), testId: "contact-modal" }));
};
export default ContactModal;

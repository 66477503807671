import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { isEqual } from 'lodash';
import { getTagWidth } from '@profesia/adamui/utils/tags';
import Tag from '@profesia/adamui/components/common/Tag';
import { generateKey } from '@profesia/adamui/utils/input';
import { Typography } from '@profesia/adamui/components/common/typography';
import { FIELD_TAG, MORE_TAGS } from '@profesia/adamui/components/common/input/selectors';
const oneDigitMoreTagsWidth = 32;
const doubleDigitsMoreTagsWidth = 40;
const paddingXTotal = 39;
const minimalSpace = 20;
const gap = 4;
export const getMoreTagsWidth = (remainingTags) => remainingTags > 9 ? doubleDigitsMoreTagsWidth : remainingTags !== 0 ? oneDigitMoreTagsWidth : 0;
const useSelectState = (ref, inputValues, value, placeholder) => {
    const [lastVisibleIndex, setLastVisibleIndex] = useState(null);
    const lastValueLength = useRef(0);
    const lastValue = useRef([]);
    const [isMultipleValues, valueLength] = Array.isArray(value)
        ? [true, value.length]
        : [false, 0];
    const getMoreTagsNumber = (lastIndex = lastVisibleIndex) => lastIndex ? valueLength - lastIndex - 1 : 0;
    useLayoutEffect(() => {
        if (!isMultipleValues || isEqual(value, lastValue.current)) {
            return;
        }
        lastValue.current = value;
        const widthOfTagWrapper = (ref.current?.offsetWidth ?? 0) - paddingXTotal;
        let remainingWidth = widthOfTagWrapper;
        let notEnoughSpace = false;
        let lastVisibleIndex = null;
        const tags = ref.current?.querySelectorAll(`.${FIELD_TAG}`);
        const setMoreTagsVisibility = (visible) => ref.current
            ?.querySelector(`.${MORE_TAGS}`)
            ?.setAttribute('style', `display: ${visible ? 'flex' : 'none'}`);
        tags?.forEach((tag, index) => {
            tag.removeAttribute('style');
            const tagWidth = getTagWidth(tag) + gap;
            const setAttributeStyleToTag = (attribute) => tag.setAttribute('style', attribute);
            const setDisplayNone = () => setAttributeStyleToTag('display: none');
            const setMaxWidth = (maxWidth) => setAttributeStyleToTag(`max-width: ${maxWidth}px`);
            const moreTagsWidth = getMoreTagsWidth(Math.max(tags.length - index - 1, 0));
            const newRemainingWidth = remainingWidth - tagWidth;
            if (!notEnoughSpace) {
                if (newRemainingWidth - moreTagsWidth < minimalSpace) {
                    if (newRemainingWidth - moreTagsWidth > 0) {
                        lastVisibleIndex = index;
                    }
                    else {
                        setMaxWidth(remainingWidth - moreTagsWidth);
                        lastVisibleIndex = moreTagsWidth ? index : null;
                    }
                    notEnoughSpace = true;
                }
                else {
                    remainingWidth = newRemainingWidth;
                }
            }
            else {
                setDisplayNone();
            }
            if (tagWidth > widthOfTagWrapper) {
                setMaxWidth(widthOfTagWrapper);
            }
        });
        lastValueLength.current = valueLength;
        setLastVisibleIndex(lastVisibleIndex);
        setMoreTagsVisibility(getMoreTagsNumber(lastVisibleIndex) !== 0);
    }, [value]);
    const renderTags = (selectedInputValues) => {
        const tags = [];
        selectedInputValues.forEach((selectedValue, index) => {
            const label = inputValues.find(({ id }) => selectedValue === id)?.label ?? '';
            tags.push(_jsx(Tag, { className: FIELD_TAG, size: "extra-small", children: label }, generateKey(index, label)));
        });
        tags.push(_jsxs(Box, { className: MORE_TAGS, display: "none", gap: 0.5, alignItems: "center", children: [_jsx("span", { children: "..." }), _jsx(Tag, { size: "extra-small", children: getMoreTagsNumber() })] }, generateKey(tags.length, MORE_TAGS)));
        return selectedInputValues.length === 0 ? (_jsx(Typography, { color: "black3", children: placeholder })) : (_jsx(Stack, { direction: "row", gap: 0.5, alignItems: "center", children: tags }));
    };
    return {
        renderTags,
    };
};
export { useSelectState };

import 'isomorphic-fetch';
import * as Sentry from '@sentry/react';
import Ajax from './../helpers/Ajax';
import Time from './../helpers/Time';
import Url from './../helpers/Url';
import GoogleAnalytics from './../helpers/GoogleAnalytics';
import User from '../helpers/User';
import Log from '../helpers/Log';
import Constants from '../helpers/Constants';
import {clearCancellation} from '../actions/ChunkCancellationActions';
import AbortControllerHelper from '../helpers/AbortControllerHelper';
import handleErrorResponse from '../helpers/handleErrorResponse';

const SENTRY_TIMEOUT = 15;

const fetchMiddleware = store => next => {
    return action => {
        const ignoreChunks = action.hasOwnProperty('payload') === true && action.payload.hasOwnProperty('ignoreChunks') === true && action.payload.ignoreChunks === true;

        if (action.hasOwnProperty('meta') === true) {
            if (action.hasOwnProperty('payload') === true && action.payload.hasOwnProperty('candidateIds') === true) {
                if (((action.meta.hasOwnProperty('requestMethod') === false || action.meta.requestMethod === 'GET') && action.payload.candidateIds.length > Constants.CANDIDATES_CHUNK_SIZE)
                    || (action.meta.requestMethod === 'POST' && action.payload.candidateIds.length > Constants.POST_CHUNK_SIZE && ignoreChunks === false)
                ) {
                    return next(action);
                }
            }
        }

        if (action.hasOwnProperty('meta') === true && action.meta.hasOwnProperty('chunks') === false && (action.meta.hasOwnProperty('requestData') === true || action.meta.hasOwnProperty('requestRoute') === true)) {
            const requestMethod = action.meta.hasOwnProperty('requestMethod') === true ? action.meta.requestMethod : 'GET';
            const requestBody = action.meta.hasOwnProperty('requestBody') === true ? action.meta.requestBody : '';

            const startTime = Time.getTime();

            fetch(Ajax.getFetchLink(action.meta), Ajax.getFetchParams(requestMethod, requestBody, AbortControllerHelper.getSignal()))
                .then(response => {
                    handleErrorResponse(response);

                    const actionDuration = Time.getTime(startTime, 'milliseconds', 2);
                    const actionDurationSeconds = Time.getTime(startTime, 'seconds', 2);
                    const companyName = User.getCompanyName(store.getState().loginManagerModel);
                    const companyId = User.getCompanyId(store.getState().loginManagerModel);

                    if (actionDuration > SENTRY_TIMEOUT * 1000) {
                        Log.warning(`Fetch request for ${action.type} exceeded ${SENTRY_TIMEOUT} seconds (${actionDurationSeconds} s)`);
                        Sentry.captureMessage(`AJAX request for ${action.type}: company ${companyName} (${companyId}) - ${actionDurationSeconds}s > ${SENTRY_TIMEOUT}s`);
                    }

                    if (User.consoleLogEnabled(store.getState().loginManagerModel) === true) {
                        Log.time(`AJAX request for ${action.type} performed in ${actionDuration} ms`);
                    }

                    GoogleAnalytics.sendActionDuration('Fetch Actions', action.type, actionDuration);

                    return response.json();
                })
                .then(responseJson => {
                    if (responseJson === undefined || (responseJson.hasOwnProperty('error') === true && responseJson.error === true)) {
                        throw responseJson;
                    }

                    return responseJson;
                })
                .then(responseJson => {
                    if (responseJson.hasOwnProperty('redirect') === true && responseJson.redirect === true) {
                        Log.message('FetchMiddleware redirecting..');

                        if (responseJson.redirectTo === 'login') {
                            Url.redirectTo(Url.buildLoginLink());
                        } else if (responseJson.redirectTo.indexOf('https') === 0) {
                            Url.redirectTo(responseJson.redirectTo);
                        } else {
                            Url.redirectTo(Url.build(responseJson.redirectTo));
                        }
                    } else if (responseJson.hasOwnProperty('alertMessage') === true) {
                        alert(responseJson.alertMessage);
                    } else if (responseJson.hasOwnProperty('message') === true) {
                        Log.api('API Response message: "' + responseJson.message + '"');
                    }

                    if (store.getState().chunkCancellation.isCancelled === true) {
                        store.dispatch(clearCancellation());
                    } else if (action.meta.hasOwnProperty('onRequestSuccess') === true) {
                        store.dispatch({
                            type: action.meta.onRequestSuccess,
                            payload: responseJson,
                            error: false,

                            meta: {
                                requestPayload: action.hasOwnProperty('payload') === true ? action.payload : {},
                            },
                        });
                    }
                })
                .catch(error => {
                    if (User.consoleLogEnabled(store.getState().loginManagerModel) === true) {
                        if (error.hasOwnProperty('alertMessage') === true) {
                            alert(error.alertMessage);
                        } else if (error.hasOwnProperty('toastMessages') === false) {
                            Log.warning('Data fetching for ' + action.type + ' terminated with error "' + error.message + '" - check the Network tab for errors');
                        }
                    }

                    if (error.hasOwnProperty('redirect') === true && error.redirect === true) {
                        Log.message('FetchMiddleware redirecting..');

                        if (error.redirectTo === 'login') {
                            Url.redirectTo(Url.buildLoginLink());
                        } else if (error.redirectTo.indexOf('https') === 0) {
                            Url.redirectTo(error.redirectTo);
                        } else {
                            Url.redirectTo(Url.build(error.redirectTo));
                        }
                    }

                    if (action.meta.hasOwnProperty('onRequestFailure') === true) {
                        store.dispatch({
                            type: action.meta.onRequestFailure,
                            error: true,
                            payload: error,

                            meta: {
                                requestPayload: action.payload,
                            },
                        });
                    }
                });
        }

        return next(action);
    };
};

export default fetchMiddleware;
